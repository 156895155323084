<template>
  <div
    :class="{
      stu_score: true,
      youxiu: value >= 90,
      zhongdeng: value > 60 && value < 90,
      yiban: value <= 60,
    }"
    @click="clickAction"
  >
    <h3>{{ title }}</h3>
    <el-input
      :readonly="read"
      size="mini"
      v-model="value"
      placeholder="请输入成绩"
      @change="changeAction(number)"
    ></el-input>
  </div>
</template>
<script>
export default {
  name: "StuScore",
  data() {
    return {
      read: false,
      value: this.val,
      t_id: this.id,
    };
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    number: {
      type: String,
      default: "0",
    },
    className: {
      type: String,
      default: "0",
    },
    zhoukaoName: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "-1",
    },
    val: {
      type: Number,
      default: 0,
    },
    kind: {
      type: Number,
      default: 0,
    },
    kaoshi: {
      type: Number,
      default: 0,
    },
    tel:{
        type: String,
        default:""
    }
  },
  async created(){
     console.log("---------------------------");
     console.log(this.title);
     console.log(this.tel);
     console.log(this.number);
     console.log(this.t_id);
     
     if (this.t_id == -1) {
        //完成数据添加
        this.$datas.meta = {
          className: this.className,
          stu_id: this.number,
          stu_name: this.title,
          tel:this.tel
        };
        let res = await this.$datas.addStu;
        console.log(res);
        this.$datas.meta = {
          kaoshi: 0,
          stu_id: this.number,
          stu_name: this.title,
          zhoukaoName: this.zhoukaoName,
        };
        let res1 = await this.$datas.addScores;
        this.t_id = res1.data.data.id;
      } 
  },
  methods: {
    async changeAction(stu_id) {
      this.read = true;
      this.value = this.value > 100 ? 100 : this.value < 0 ? 0 : this.value;
      if (this.t_id == -1) {
        //完成数据添加
        this.$datas.meta = {
          className: this.className,
          stu_id: stu_id,
          stu_name: this.title,
        };
        let res = await this.$datas.addStu;
        this.$datas.meta = {
          kaoshi: parseFloat(this.value),
          stu_id: stu_id,
          stu_name: this.title,
          zhoukaoName: this.zhoukaoName,
        };
        let res1 = await this.$datas.addScores;
        console.log(res, res1);
        this.t_id = res1.data.id;
      } else {
        //更新数据
        if (this.kind == 0) {
          this.$datas.meta = { t_id: this.t_id, kaoshi: this.value };
        } else {
          this.$datas.meta = { t_id: this.t_id, bukao: this.value };
        }
        let res = await this.$datas.updateScore;
        console.log(res);
      }
    },
    clickAction() {
      this.read = false;
    },
  },
};
</script>
<style lang="less" scoped>
.stu_score {
  width: 70px;
  text-align: center;
  position: relative;
  background: red;
  padding: 5px 10px;
  margin: 20px;
  color: white;
  border-radius: 5px;
  float: left;
  cursor: pointer;
  h3 {
    font-size: 14px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.youxiu {
  background-color: #f4f4f4;
  color: #555;
  border: 1px solid #555;
}
.zhongdeng {
  background-color: #409eff;
  border: 1px solid #409eff;
}
.yiban {
  background-color: #f56c6c;
  border: 1px solid #f56c6c;
}
</style>
